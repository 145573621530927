import React, { useState, useEffect } from 'react';
import _, { isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Spin } from 'react-cssfx-loading';
import {
	Pencil,
	Save,
	ChevronLeft,
	ChevronRight,
	ChevronDown,
	Info,
	XCircle,
	X,
	Check,
	Boxes,
	CloudSun,
	Paperclip,
	Trash2,
	Loader,
	Plus,
	PlusCircle,
	Box,
	Share2,
	Link2,
	LinkIcon,
	Square,
	Globe,
	Shapes,
	Hexagon,
} from 'lucide-react';

import { Separator } from '@radix-ui/react-select';

import api from '@/global-components/api'
import helpers from '@/global-components/components/helpers';
import {
	ProductType,
	PartType,
	ProductStatus,
	FileType,
	PrintRequest,
	User,
	SlicerParameters,
	ProductPartLinkType,
} from '@/global-components/types';
import { useToast } from '@/global-components/components/ui/use-toast';

import { Badge } from '@/global-components/components/ui/badge';
import { Button } from '@/global-components/components/ui/button';
import { Textarea } from '@/global-components/components/ui/textarea';
import Filament from '@/global-components/components/bw/filament/Filament';
import ProgressBar from '@/global-components/components/bw/progressBar/ProgressBar';
import DownloadButton from '@/global-components/components/bw/DownloadButton';
import ImageOverlay from '@/global-components/components/bw/ImageOverlay';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/global-components/components/ui/collapsible';

import GcodeFileInfo from '@/components/productRelated/GcodeFileInfo';
import PrintProduct from '@/components/productRelated/printProduct/PrintProduct';
import MessagesPopover from '@/components/messagesPopover/MessagesPopover';
import CustomFileUpload from '@/components/customFileUpload/CustomFileUpload';
import FileTag, { FileExtensionType } from '@/global-components/components/bw/FileTag';

import ProductDelete from '../productDelete/ProductDelete';
import SlicerSettings from '@/components/productRelated/SlicerSetttings';
import EmissionsData from '@/components/EmissionsData';
import { Input } from '@/global-components/components/ui/input';
import { Checkbox } from '@/global-components/components/ui/checkbox';
import FileInfo from '@/components/productRelated/FileInfo';
import Dropdown from '@/global-components/components/dropdown/Dropdown';
import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import Link from '@/global-components/components/bw/Link';
import Combobox from '@/global-components/components/combobox/Combobox';
import ProductParts from './ProductParts';

type props = {
	product: ProductType;
	files: FileType[];
	filesToUpload: File[];
	user: User | undefined;
	classNames: string;
	setFilesToUpload: Function;
	refetchProduct: Function;
	isSharedWithYou?: boolean;
	viewFileById?: Function;
};

const ProductDetails = ({
	product,
	files,
	filesToUpload,
	setFilesToUpload,
	user,
	classNames,
	refetchProduct,
	isSharedWithYou,
	viewFileById,
}: props) => {
	const { toast } = useToast();
	const navigate = useNavigate();

	const [updateProductMutation] = useMutation(api.products.mutations.UPDATE_PRODUCT);
	const [editing, setEditing] = useState<boolean>(false);
	const [editedProduct, setEditedProduct] = useState<ProductType | undefined>(undefined);

	const [printRequestsOpen, setPrintRequestsOpen] = useState<boolean>(false);
	const [linksOpen, setLinksOpen] = useState<boolean>(false);
	const [perferencesOpen, setPreferencesOpen] = useState(true);
	const [gcodeFilesOpen, setGcodeFilesOpen] = useState(false);
	const [stlFilesOpen, setStlFilesOpen] = useState(false);
	const [otherFilesOpen, setOtherFilesOpen] = useState(false);
	const [impactDataOpen, setImpactDataOpen] = useState<boolean>(false);
	const [partsOpen, setPartsOpen] = useState<boolean>(false);

	const [unassignedFiles, setUnassignedFiles] = useState<FileType[]>([]);
	const [newPartFiles, setNewPartFiles] = useState<FileType[]>([]);
	const [newPartName, setNewPartName] = useState<string | undefined>(undefined);
	const [slicerSettingsOpen, setSlicerSettingsOpen] = useState<boolean>(false);
	const [stl, setStl] = useState<FileType | null>(null);
	const [isSlicing, setIsSlicing] = useState<boolean>(false);
	const [slicerParameters, setSlicerParameters] = useState<SlicerParameters | null>(null);
	const [slicedExternally, setSlicedExternally] = useState<boolean>(false);

	const latestVersion: boolean = product.latestVersion ? true : false;

	const [imageOpen, setImageOpen] = useState<string | null>(null);

	const [updating, setUpdating] = useState(false);
	const [reloadKey, setReloadKey] = useState<number>(Date.now());

	const [deletePrintRequest] = useMutation(api.printRequests.mutations.DELETE_PRINT_REQUEST);
	const [resliceFile] = useMutation(api.products.mutations.RESLICE_FILE);

	const printRequestQuery = useQuery(api.printRequests.queries.GET_PRINT_REQUESTS_FOR_USER, {
		pollInterval: 5000,
	});

	const fileSlicerQuery = useQuery(api.products.queries.FILE_SLICER, {
		variables: {
			stlFileId: stl?.fileId,
		},
		pollInterval: 5000,
		fetchPolicy: 'no-cache',
		skip: true,
	});


	const [deleteLinkMutation] = useMutation(api.products.mutations.DELETE_LINK);
	const [createLinkMutation, { loading: creatingLink }] = useMutation(api.products.mutations.CREATE_LINK);
	const [createNewLinkFormVisible, setCreateNewLinkFormVisible] = useState<boolean>(false);
  const [newLinkName, setNewLinkName] = useState<string | undefined>(undefined)
  const [newLinkUrl, setNewLinkUrl] = useState<string | undefined>(undefined)

  const clearNewLink = () => {
    setCreateNewLinkFormVisible(false)
    setNewLinkName(undefined)
    setNewLinkUrl(undefined)
  }

  const createLink = () => {
    createLinkMutation({
      variables: {
        linkName: newLinkName,
        linkUrl: newLinkUrl,
        productId: product.productId
      }
    }).then((result) => {
      if (result.data.createLink.success) {
        clearNewLink()
        toast({
          title: 'Link added successfully',
          variant: 'success',
          duration: 3000,
        })
      } else {
        toast({
          title: 'Something went wrong',
          variant: 'destructive',
          duration: 3000,
        });
      }
    })
  }

	const removeLink = (id: string) => {
		deleteLinkMutation({
			variables: {  
				id: id,
			},
		})
			.then((result) => {
				if (result.data.deleteLink.success) {
					toast({
						title: 'Link deleted',
						variant: 'success',
						duration: 3000,
					})
				} else {
					toast({
						title: 'Something went wrong',
						variant: 'destructive',
						duration: 3000,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				toast({
					title: 'Something went wrong',
					variant: 'destructive',
					duration: 3000,
				});
			});
	};

	const [createPartMutation, { loading: creatingPart }] = useMutation(api.products.mutations.CREATE_PART_FOR_PRODUCT);
	const [deletePartMutation, { loading: deletingPart }] = useMutation(api.products.mutations.DELETE_PART);
	const [updatePartMutation, { loading: updatingPart }] = useMutation(api.products.mutations.UPDATE_PART);
	const [partsBeingDeleted, setPartsBeingDeleted] = useState<string[]>([]);
	const [partsBeingUpdated, setPartsBeingUpdated] = useState<string[]>([]);
	const [filesBeingRemoved, setFilesBeingRemoved] = useState<string[]>([]);
	const [partAddFilePopoverOpen, setPartAddFilePopoverOpen] = useState<string | undefined>(undefined);
	const [filesToAddToPart, setFilesToAddToPart] = useState<FileType[]>([]);
	const [partNameBuffer, setPartNameBuffer] = useState<string | undefined>(undefined);
	const [partNameFocused, setPartNameFocused] = useState<string | undefined>(undefined);

	const [parts, setParts] = useState<PartType[]>([]);
	const [linkedParts, setLinkedParts] = useState<ProductPartLinkType[]>([])
	const [createNewPartFormVisible, setCreateNewPartFormVisible] = useState<boolean>(false);
	const partsQuery = useQuery(api.products.queries.GET_PARTS_OF_PRODUCT, {
		variables: {
			productId: product.productId,
		},
		pollInterval: 3000,
	});

	const linkedPartsQuery = useQuery(api.products.queries.GET_LINKED_PARTS_OF_PRODUCT, {
		variables: {
			productId: product.productId
		},
		pollInterval: 3000,
	})

	const createPart = () => {
		const fileIds: string[] = newPartFiles.map((file) => file.fileId);
		createPartMutation({ variables: { productId: product.productId, fileIds: fileIds, partName: newPartName } })
			.then((result) => {
				setNewPartFiles([]);
				setNewPartName(undefined);
				setCreateNewPartFormVisible(false);
				toast({
					title: 'Part Created',
					variant: 'success',
					duration: 3000,
				});
			})
			.catch((error) => {
				console.log(error);
				toast({
					title: 'Part Creation Failed',
					variant: 'destructive',
					duration: 3000,
				});
			});
	};

	const onPartNameBlur = (part: PartType, newName: string) => {
		if (partNameBuffer === part.partName) {
			setPartNameBuffer(undefined);
			setPartNameFocused(undefined);
			return;
		}
		setPartsBeingUpdated([...partsBeingUpdated, part.partId]);
		updatePartMutation({ variables: { partId: part.partId, partName: newName } })
			.then((result) => {
				partsQuery.refetch();
				setPartNameBuffer(undefined);
				setPartNameFocused(undefined);
				toast({
					title: 'Updated part name',
					variant: 'success',
					duration: 3000,
				});
				setPartsBeingUpdated(partsBeingUpdated.filter((partId) => partId !== part.partId));
			})
			.catch((error) => {
				setPartNameBuffer(undefined);
				setPartNameFocused(undefined);
				toast({
					title: 'Updating part failed',
					variant: 'destructive',
					duration: 3000,
				});
				setPartsBeingUpdated(partsBeingUpdated.filter((partId) => partId !== part.partId));
			});
	};

	const addFilesToPart = (partId: string) => {
		setPartsBeingUpdated([...partsBeingUpdated, partId]);
		const fileIds: string[] = filesToAddToPart.map((file) => file.fileId);
		updatePartMutation({ variables: { partId: partId, addFileIds: fileIds } })
			.then((result) => {
				partsQuery.refetch();
				setPartNameBuffer(undefined);
				toast({
					title: 'Updated part name',
					variant: 'success',
					duration: 3000,
				});
				setPartsBeingUpdated(partsBeingUpdated.filter((partId) => partId !== partId));
			})
			.catch((error) => {
				setPartNameBuffer(undefined);
				toast({
					title: 'Updating part failed',
					variant: 'destructive',
					duration: 3000,
				});
				setPartsBeingUpdated(partsBeingUpdated.filter((partId) => partId !== partId));
			});
	};

	const removeFileFromPart = (partId: string, fileId: string) => {
		setFilesBeingRemoved([...filesBeingRemoved, fileId]);
		updatePartMutation({ variables: { partId: partId, removeFileIds: [fileId] } })
			.then((result) => {
				partsQuery.refetch();
				setFilesBeingRemoved(filesBeingRemoved.filter((id) => id !== fileId));
				toast({
					title: 'Files have been removed from part',
					variant: 'success',
					duration: 3000,
				});
			})
			.catch((error) => {
				toast({
					title: 'Removing files failed',
					variant: 'destructive',
					duration: 5000,
				});
				console.log(error);
				setFilesBeingRemoved(filesBeingRemoved.filter((id) => id !== fileId));
				setPartsBeingUpdated(partsBeingUpdated.filter((partId) => partId !== partId));
			});
	};

	const deletePart = (id: string) => {
		setPartsBeingDeleted([...partsBeingDeleted, id]);
		deletePartMutation({ variables: { partId: id } })
			.then((result) => {
				partsQuery.refetch();
				setParts(parts.filter((part) => part.partId !== id));
				setPartsBeingDeleted(partsBeingDeleted.filter((partId) => partId !== id));
			})
			.catch((error) => {
				setPartsBeingDeleted(partsBeingDeleted.filter((partId) => partId !== id));
			});
	};

	const cancelNewPart = () => {
		setNewPartName(undefined);
		setNewPartFiles([]);
		setCreateNewPartFormVisible(false);
	};

	useEffect(() => {
		if (partsQuery.data) {
			const queryParts: PartType[] = [...partsQuery.data.partsForProduct].sort((a: PartType, b: PartType) =>
				a.partName.localeCompare(b.partName)
			);
			setParts(queryParts)
		}
	}, [partsQuery])
	
	useEffect(() => {
		if (linkedPartsQuery.data) {
			const linkedQueryParts: ProductPartLinkType[] = [...linkedPartsQuery.data.linkedPartsForProduct].sort((a: ProductPartLinkType, b: ProductPartLinkType) =>
				a.part.partName.localeCompare(b.part.partName)
			);
			setLinkedParts(linkedQueryParts)
		}
	}, [linkedPartsQuery])

	useEffect(() => {
		const assignedFileIds: string[] = parts.flatMap((part) => part.files.map((file) => file.fileId));
		const _unassignedFiles = files.filter((file) => !assignedFileIds.find((fileId) => fileId === file.fileId));

		setUnassignedFiles(_unassignedFiles);
	}, [parts, files]);

	const [userParts, setUserParts] = useState<PartType[]>([])
	const userPartsQuery = useQuery(api.products.queries.GET_PARTS_OF_USER, {
		variables: {
			userId: user?.userId
		},
		pollInterval: 3000
	})

	useEffect(() => {
		if (userPartsQuery.data) {
			setUserParts(userPartsQuery.data.partsOfUser)
		}
	}, [userPartsQuery])
	

	const toggleEditProduct = (cancel?: boolean) => {
		if (editing) {
			if (_.isEqual(product, editedProduct) || cancel) {
				setEditing(false);
				setEditedProduct(undefined);
			} else {
				if (!editedProduct?.description) {
					toast({
						title: "Can't update",
						variant: 'destructive',
						description: 'Your product needs to have a description',
					});
					return;
				}
				setUpdating(true);
				updateProductMutation({ variables: { productId: product.productId, description: editedProduct?.description } })
					.then((result: any) => {
						helpers.log('updating product most likely successful: ', result);
						refetchProduct();
					})
					.catch((error: any) => {
						helpers.log(error);
					})
					.finally(() => {
						setEditing(false);
						setEditedProduct(undefined);
						setUpdating(false);
					});
			}
		} else {
			const _product: ProductType = product;
			setEditedProduct(_product);
			setEditing(true);
		}
	};

	const updateProductDescription = (description: string) => {
		setEditedProduct((prevProduct) => {
			if (!prevProduct) return prevProduct;

			return {
				...prevProduct,
				description,
			};
		});
	};

	const goBackToAllProducts = () => {
		navigate(`/?reload=true`);
	};

	const getTotalPrintsForThisProduct = (printRequests: PrintRequest[]): number => {
		const productPrintRequests: PrintRequest[] = printRequests?.filter((printRequest: PrintRequest) =>
			files.filter(helpers.filters.isFileGcode)?.some((gcode: any) => gcode.fileId === printRequest.file.fileId)
		);
		return (
			productPrintRequests?.reduce(
				(totalCompleted: number, item: PrintRequest) => totalCompleted + item.jobsCompleted,
				0
			) || 0
		);
	};

	const handleFilesChange = (_files: File[]) => {
		setFilesToUpload([..._files]);
	};

	const handleDeletePrintRequest = (requestId: string) => {
		deletePrintRequest({ variables: { requestId: requestId } })
			.then((result: any) => {
				if (result.data.deletePrintRequest.success) {
					toast({
						title: 'Delete Print Request',
						description: 'Successfully deleted print request.',
						variant: 'success',
						duration: 3000,
					});
				} else {
					toast({
						title: 'Delete Print Request',
						variant: 'destructive',
						description: 'Error trying to delete the print request: ' + result.data.deletePrintRequest.error,
					});
				}
			})
			.catch((err) => {
				toast({
					title: 'Error',
					variant: 'destructive',
					description: 'An error occured while connecting to the server, please try again.',
					duration: 5000,
				});
			})
			.finally(() => {
				printRequestQuery.refetch();
			});
	};

	const filterActivePrintRequestsForProduct = (printRequests: PrintRequest[]): PrintRequest[] => {
		return printRequests
			?.filter((printRequest: PrintRequest) => printRequest.jobsCompleted !== printRequest.quantity)
			?.filter((printRequest: PrintRequest) =>
				files.filter(helpers.filters.isFileGcode)?.some((gcode: any) => gcode.fileId === printRequest.file.fileId)
			);
	};

	const openImageViewer = (id: string) => {
		const file: FileType | undefined = files.find((file) => file.fileId === id);
		if (!file) {
			return;
		}
		const isImage: boolean =
			helpers.extractFileTypeFromFilename(file.fileName).toLowerCase() === 'jpg' || 'png' || 'jpeg' ? true : false;
		if (isImage) {
			setImageOpen(file.presignedUrl);
		}
	};

	useEffect(() => {
		setReloadKey(Date.now());
		const _stl: FileType = files.filter(helpers.filters.isFileStl)[0];
		setStl(_stl);
	}, [files]);

	useEffect(() => {
		if (fileSlicerQuery.data) {
			setIsSlicing(fileSlicerQuery.data.fileSlicer.isSlicing);
			setSlicerParameters(fileSlicerQuery.data.fileSlicer);
			setSlicedExternally(false);
		} else {
			setSlicedExternally(true);
		}
	}, [fileSlicerQuery.data]);

	useEffect(() => {
		if (!isSlicing) {
			refetchProduct(true);
		}
	}, [isSlicing]);

	const resliceWith = (params: SlicerParameters, gcodeFileId: string) => {
		setSlicerParameters(params);
		resliceFile({
			variables: {
				productId: product.productId,
				stlFileId: files.filter(helpers.filters.isFileStl)[0].fileId,
				rotateX: params.rotateX,
				rotateY: params.rotateY,
				rotateZ: params.rotateZ,
				nozzleDiameter: params.nozzleDiameter,
				layerHeight: params.layerHeight,
				extrusionWidth: params.extrusionWidth,
				bottomSolidLayers: params.bottomSolidLayers,
				firstLayerHeight: params.firstLayerHeight,
				speed: params.speed,
				fanSpeed: params.fanSpeed,
				temperature: params.temperature,
			},
		})
			.then((result: any) => {
				fileSlicerQuery.refetch();
				refetchProduct(true);
			})
			.catch((err: any) => {
				console.log('error: ', err);
			});
	};

	return (
		<div className={`product-details-container max-w-[40vw] xl:max-w-[500px] 2xl:max-w-[650px] ${classNames}`}>
			<ImageOverlay url={imageOpen} callback={() => setImageOpen(null)} />
			<div className="product-details-header">
				<div>
					<h1 className="product-title flex items-center gap-2 text-2xl mt-[8px]">
						{isSharedWithYou && <Share2 className="h-6 w-6 text-bw-grey" />}
						{product?.productTitle}
					</h1>
					<div className="flex gap-2 items-center mt-1.5 -left-0.5 relative text-bw-green">
						<Badge variant={product?.status.toLowerCase() as ProductStatus}>{product?.status}</Badge>
					</div>
				</div>
				<div className="flex absolute right-0 gap-2">
					<ProductDelete reference={product?.productVersion[0].reference} callback={goBackToAllProducts} />
					{editing ? (
						<div className="flex gap-0">
							<Button variant="minimal" disabled={updating} size="icon" onClick={() => toggleEditProduct(true)}>
								<X className="h-4 w-4" />
							</Button>
							<Button variant="bwconfirm" disabled={updating} size="icon" onClick={() => toggleEditProduct()}>
								{updating ? (
									<Spin className="inline-spin h-4 w-4" color="#ffffff" width="20px" height="20px" duration="0.3s" />
								) : (
									<Save className="h-4 w-4" />
								)}
							</Button>
						</div>
					) : (
						<Button
							variant="bwsecondary"
							disabled={user?.userId !== product?.createdBy?.userId || !latestVersion}
							size="icon"
							onClick={() => toggleEditProduct()}
						>
							<Pencil className="h-4 w-4" />
						</Button>
					)}
					{product?.productId ? <MessagesPopover productId={product?.productId} /> : null}
					{product?.status === 'approved' ? (
						<PrintProduct
							buttonTitle="Print"
							product={product}
							gcodeFiles={files.filter(helpers.filters.isFileGcode)}
						/>
					) : null}
					{product?.status === 'production' ? (
						<PrintProduct
							buttonTitle="Produce"
							product={product}
							gcodeFiles={files.filter(helpers.filters.isFileGcode)}
						/>
					) : null}
				</div>
			</div>
			
			<div className="product-details-scroll-container">
				<div className='meta-and-description flex flex-col gap-1 mb-4'>
					<div className='meta-data flex gap-1'>
						<div className="text-xs opacity-30 hover:opacity-100">
									Created: <b>{helpers.formatDateToDDMMYY(product?.createdAt, true)}</b>
						</div>
						{product?.createdBy ? (
							<div className="text-xs opacity-30 hover:opacity-100">
								User: <b>{product.createdBy.firstName}</b>
							</div>
						) : null}
						{product?.status.toLowerCase() === 'approved' || product?.status.toLowerCase() === 'production' ? (
							<div className="text-xs opacity-30 hover:opacity-100">
								Printed: <b>{getTotalPrintsForThisProduct(printRequestQuery.data?.userPrintRequests)}</b>
							</div>
						) : null}
					</div>
					{editing && editedProduct ? (
						<div className="product-details-description edit">
							<Textarea
								rows={1}
								className="text-md"
								name="productDescription"
								placeholder="Product description"
								value={editedProduct.description}
								onChange={(event) => updateProductDescription(event.target.value)}
							/>
						</div>
					) : (
						<div className="product-details-description text-md">{product?.description}</div>
					)}
				</div>
				{/* 
            { !files.filter(helpers.filters.isFileGcode)?.length ? <CustomFileUpload onFilesChange={handleFilesChange} key={reloadKey} small type='gcode'/> : null} */}
				<Collapsible
					open={printRequestsOpen}
					onOpenChange={setPrintRequestsOpen}
					className={
						filterActivePrintRequestsForProduct(printRequestQuery.data?.userPrintRequests)?.length > 0
							? 'w-[100%] space-y-2 mb-4 '
							: 'hidden w-[100%] space-y-2 mb-4'
					}
				>
					<CollapsibleTrigger asChild>
						<div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
							<div>
								<ChevronRight
									className={`h-4 w-4 transition-all ease-out-expo duration-150 ${printRequestsOpen && 'rotate-90'}`}
								/>
								<span className="sr-only">Toggle</span>
							</div>
							<h4 className="flex items-center gap-1 text-sm font-semibold">
								Print requests{' '}
								<span className="text-bw-green/30 font-normal">
									({filterActivePrintRequestsForProduct(printRequestQuery.data?.userPrintRequests)?.length})
								</span>
							</h4>
							<Separator className="flex-1 grow border-b relative top-[1px] border-bw-grey ml-1" />
						</div>
					</CollapsibleTrigger>
					<CollapsibleContent className="space-y-2">
						<div className="flex flex-col gap-4">
							{filterActivePrintRequestsForProduct(printRequestQuery?.data?.userPrintRequests)?.map(
								(request: PrintRequest, index: number) => (
									<div className={request.interupted ? 'flex flex-col gap-1 opacity-30' : 'flex flex-col gap-1'}>
										<ProgressBar
											bright
											height={4}
											progress={(Number(request.jobsCompleted) / Number(request.quantity)) * 100}
										/>
										<div className="flex justify-between gap-2 text-sm items-center">
											<div>
												<div className="flex gap-1 grow">
													<div>
														{request.jobsCompleted}/{request.quantity}
													</div>
													<div className="font-bold truncate max-w-[70%]">{request.file.fileName}</div>
												</div>
												<div className="print-request-details flex gap-2 relative text-bw-green/30">
													<div className="text-xs">
														Location: <b>{request.factory?.name}</b> -{' '}
													</div>
													<div className="text-xs">Requested: {helpers.formatDateToDDMMYY(request.issuedAt)}</div>
												</div>
											</div>
											<div className="text-right">
												<Button
													variant="minimal"
													size="smallicon"
													onClick={() => handleDeletePrintRequest(request.requestId)}
												>
													<XCircle className="h-4 w-4"></XCircle>
												</Button>
											</div>
										</div>
									</div>
								)
							)}
						</div>
					</CollapsibleContent>
				</Collapsible>

				<Collapsible open={partsOpen} onOpenChange={setPartsOpen} className={`space-y-2 mb-4`}>
					<CollapsibleTrigger asChild>
						<div className={`flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1
							${(partsQuery.loading || linkedPartsQuery.loading) && 'opacity-70 pointer-events-none'}`}>
							<div>
								<ChevronRight
									className={`h-4 w-4 transition-all ease-out-expo duration-150 ${partsOpen && 'rotate-90'}`}
								/>
								<span className="sr-only">Toggle</span>
							</div>
							{(partsQuery.loading || linkedPartsQuery.loading) 
								? <h4 className={`flex items-center gap-1 text-sm font-semibold`}>
									<Loader className='h-4 w-4 animate-spin' /> Loading parts
								</h4>
								: <h4 className={`flex items-center gap-2 text-sm font-semibold`}>
										<div className='flex items-center gap-1'>
											<Hexagon className="h-4 w-4" />
											Parts ({parts.length}) {!latestVersion && ' (read only)'}
										</div>
										<div className={`h-1 w-1 rounded-full bg-bw-green/30 ${linkedParts.length === 0 && 'hidden'}`}></div>
										{linkedParts.length > 0 &&
											<div className='flex items-center gap-1'>
												<Link2 className='h-4 w-4' />
												<span>Linked parts ({linkedParts.length})</span>
											</div>
										}
								</h4>
							}
							
							<Separator className="flex-1 grow border-b relative top-[1px] border-bw-grey ml-1" />
						</div>
					</CollapsibleTrigger>
					<CollapsibleContent className={`space-y-2`}>
						<div className="">
						<ProductParts
							product={product}
							parts={parts}
							linkedParts={linkedParts}
							userParts={userParts}
							files={files}
							unassignedFiles={unassignedFiles}
							latestVersion={latestVersion}
							viewFileById={viewFileById}
							refetchParts={() => {
								partsQuery.refetch();
								linkedPartsQuery.refetch();
							}}
						/>
							<div className=""></div>
						</div>
					</CollapsibleContent>
				</Collapsible>

				<Collapsible open={gcodeFilesOpen} onOpenChange={setGcodeFilesOpen} className="w-[100%] space-y-2  mb-4">
					<CollapsibleTrigger asChild>
						<div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
							<div>
								<ChevronRight
									className={`h-4 w-4 transition-all ease-out-expo duration-150 ${gcodeFilesOpen && 'rotate-90'}`}
								/>
								<span className="sr-only">Toggle</span>
							</div>
							<h4 className="flex items-center gap-1 text-sm font-semibold">
								<Paperclip className="h-4 w-4" /> {parts.length ? 'Unassigned files (' : 'Files ('}{unassignedFiles.length})
								{!latestVersion && ' (read only)'}
							</h4>
							<Separator className="flex-1 grow border-b relative top-[1px] border-bw-grey ml-1" />
						</div>
					</CollapsibleTrigger>
					<CollapsibleContent className={`space-y-2`}>
						{unassignedFiles.filter(helpers.filters.isFileGcode)?.map((file: any, index: number) => {
							return (
								<div className="flex flex-col gap-1">
									<GcodeFileInfo
										product={product}
										updating={isSlicing}
										file={file}
										key={index}
										index={index}
										showActions={true}
										user={user}
										showDetails
										viewOnly={!latestVersion}
										view={(id: string) => viewFileById && viewFileById(id)}
									/>
									{slicerParameters && (
										<SlicerSettings
											show={slicerSettingsOpen}
											settings={slicerParameters}
											isSlicing={isSlicing}
											resliceWith={(params: SlicerParameters) => resliceWith(params, file.fileId)}
										/>
									)}
								</div>
							);
						})}
						{unassignedFiles.filter(helpers.filters.isFileStl)?.map((file: any, index: number) => {
							return (
								<FileInfo
									file={file}
									product={product}
									callback={(id: string) => files.filter((file) => file.fileId !== id)}
									viewOnly={!latestVersion}
									view={(id: string) => viewFileById && viewFileById(id)}
								/>
							);
						})}
						{unassignedFiles.filter(helpers.filters.isFileImage)?.map((file: any, index: number) => {
							return (
								<FileInfo
									file={file}
									product={product}
									previewImage
									callback={(id: string) => files.filter((file) => file.fileId !== id)}
									viewOnly={!latestVersion}
									view={() => setImageOpen(file.presignedUrl)}
								/>
							);
						})}
						{unassignedFiles.filter(helpers.filters.isOtherFile)?.map((file: any, index: number) => {
							return (
								<FileInfo
									file={file}
									product={product}
									callback={(id: string) => files.filter((file) => file.fileId !== id)}
									viewOnly={!latestVersion}
									view={(id: string) => viewFileById && viewFileById(id)}
								/>
							);
						})}
						<CustomFileUpload onFilesChange={handleFilesChange} key={reloadKey} small type="anything" />
						{/* {files.filter(helpers.filters.isFileGcode)?.length ? <CustomFileUpload onFilesChange={handleFilesChange} key={reloadKey} small type='gcode' /> : null } */}
					</CollapsibleContent>
				</Collapsible>

				{product?.preferences ? (
					<Collapsible open={perferencesOpen} onOpenChange={setPreferencesOpen} className="w-[100%] space-y-2">
						<CollapsibleTrigger asChild>
							<div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
								<div>
									{perferencesOpen ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
									<span className="sr-only">Toggle</span>
								</div>
								<h4 className="text-sm font-semibold">Preferences</h4>
								<Separator className="flex-1 grow border-b relative top-[1px] border-bw-grey ml-1" />
							</div>
						</CollapsibleTrigger>
						<CollapsibleContent className="space-y-2 flex flex-col gap-2">
							<div className="flex justify-between gap-20">
								<div className="text-sm text-bw-green flex flex-1 justify-between">
									<div className="opacity-30">Layer height:</div>
									<div className="font-bold">{product.preferences.layerHeight} mm</div>
								</div>
								<div className="text-sm text-bw-green flex flex-1 justify-between">
									<div className="opacity-30">Filament:</div>
									<div className="font-bold flex items-center gap-1">
										<Filament color={product.preferences.filament?.colour.hexCode || '#333333'} active={true} med />{' '}
										{product.preferences.filament?.material.displayName} -{' '}
										{product.preferences.filament?.colour.displayName}
									</div>
								</div>
							</div>

							<div className="flex justify-between gap-20">
								<div className="text-sm text-bw-green flex flex-1 justify-between">
									<div className="opacity-30">Layer width:</div>
									<div className="font-bold">{product.preferences.layerWidth} mm</div>
								</div>
								<div className="text-sm text-bw-green flex flex-1 justify-between">
									<div className="opacity-30">Vase mode:</div>
									<div className="font-bold flex items-center gap-1">
										{product.preferences.vaseMode ? <Check className="w-4 h-4" /> : <X className="w-4 h-4" />}
									</div>
								</div>
							</div>

							<div className="flex justify-between gap-20">
								<div className="text-sm text-bw-green flex flex-1 justify-between">
									<div className="opacity-30">Nozzle size:</div>
									<div className="font-bold">{product.preferences.nozzleSize} mm</div>
								</div>
								<div className="text-sm text-bw-green flex flex-1 justify-between">
									<div className="opacity-30">Vase mode Base:</div>
									<div className="font-bold flex items-center gap-1">
										{product.preferences.vaseModeBase ? <Check className="w-4 h-4" /> : <X className="w-4 h-4" />}
									</div>
								</div>
							</div>

							<div className="flex justify-between gap-20">
								<div className="text-sm text-bw-green flex flex-1 justify-between">
									<div className="opacity-30">Infill:</div>
									<div className="font-bold">{product.preferences.infill} %</div>
								</div>
								<div className="text-sm text-bw-green flex flex-1 justify-between">
									<div className="opacity-30">In depth review:</div>
									<div className="font-bold flex items-center gap-1">
										{product.preferences.indepthPreview ? <Check className="w-4 h-4" /> : <X className="w-4 h-4" />}
									</div>
								</div>
							</div>
						</CollapsibleContent>
					</Collapsible>
				) : null}

<Collapsible open={linksOpen} onOpenChange={setLinksOpen} className={`space-y-2 mb-4`}>
					<CollapsibleTrigger asChild>
						<div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
							<div>
								<ChevronRight
									className={`h-4 w-4 transition-all ease-out-expo duration-150 ${linksOpen && 'rotate-90'}`}
								/>
								<span className="sr-only">Toggle</span>
							</div>
							<h4 className="flex items-center gap-1 text-sm font-semibold">
								<Globe className="h-4 w-4" />
								{product.links.length ? product.links.length : null} 
                {product.links.length > 1 && 'links'} {product.links.length === 0 && 'Links'} {product.links.length === 1 && 'link'}
							</h4>
							<Separator className="flex-1 grow border-b relative top-[1px] border-bw-grey ml-1" />
						</div>
					</CollapsibleTrigger>
					<CollapsibleContent className="flex flex-col gap-2">
						{product.links.map((link) => (
							<Link link={link} remove={removeLink} />
						))}
						{createNewLinkFormVisible ? (
							<div className={`flex flex-col gap-1 ${product.links.length && 'mt-2'}`}>
                <div className='flex items-center gap-1'>
                  <Input
                    type="text"
                    className=''
                    name="newLinkName"
                    placeholder="Give your link a name"
                    value={newLinkName ?? ''}
                    onChange={(e) => setNewLinkName(e.target.value)}
                  />
                  <Input
                    type="text"
                    className=''
                    name="newLinkUrl"
                    placeholder="and a valid URL"
                    value={newLinkUrl ?? ''}
                    onChange={(e) => setNewLinkUrl(e.target.value)}
                  />
                </div>
								<div className="flex gap-1 items-center">
									<Button variant="bwsecondary" className="" disabled={creatingLink} onClick={() => clearNewLink()}>
										Cancel
									</Button>
									<Button
										variant="bwconfirm"
										disabled={!(newLinkName && helpers.isValidUrl(newLinkUrl)) || creatingLink}
										className=""
										onClick={() => createLink()}
									>
										{creatingLink ? <Loader className="h-4 w-4 animate-spin" /> : <div>Save</div>}
									</Button>
								</div>
							</div>
						) : (
							<Button
								variant="bwprimary"
								onClick={() => setCreateNewLinkFormVisible(true)}
								className={`w-min ${!latestVersion && 'hidden'} whitespace-nowrap ${product.links.length && 'mt-0'}`}
							>
								<Plus className="h-4 w-4" /> Add link
							</Button>
						)}
					</CollapsibleContent>
				</Collapsible>


				<Collapsible open={impactDataOpen} onOpenChange={setImpactDataOpen} className={`space-y-2 mb-4`}>
					<CollapsibleTrigger asChild>
						<div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
							<div>
								<ChevronRight
									className={`h-4 w-4 transition-all ease-out-expo duration-150 ${impactDataOpen && 'rotate-90'}`}
								/>
								<span className="sr-only">Toggle</span>
							</div>
							<h4 className="flex items-center gap-1 text-sm font-semibold">
								<CloudSun className="h-4 w-4" />
								Impact Data
							</h4>
							<Separator className="flex-1 grow border-b relative top-[1px] border-bw-grey ml-1" />
						</div>
					</CollapsibleTrigger>
					<CollapsibleContent className="space-y-2">
						<div className="">
							<EmissionsData files={files} />
						</div>
					</CollapsibleContent>
				</Collapsible>
				
			</div>
		</div>
	);
};

export default ProductDetails;
