import api from '@/global-components/api'
import { Button } from '@/global-components/components/ui/button'
import { Checkbox } from '@/global-components/components/ui/checkbox'
import { Dialog, DialogHeader, DialogTrigger, DialogContent, DialogFooter } from '@/global-components/components/ui/dialog'
import { Input } from '@/global-components/components/ui/input'
import { Label } from '@/global-components/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/global-components/components/ui/select'
import { FileType, ProductType } from '@/global-components/types'
import { useMutation, useQuery } from '@apollo/client'
import { DialogTitle } from '@radix-ui/react-dialog'
import { AlertCircle, Download, Loader } from 'lucide-react'
import React, { useState, useEffect } from 'react'
import { Spin } from 'react-cssfx-loading'

export const knockOffTypes = {
	default: 'Default knock off',
	default_with_shovel: 'Default with shovel',
	lift_and_knock: 'Lift up baby',
	wait_for_operator: 'Wait for Operator',
	custom: 'Custom knock off position',
}

export const sheets = {
	textured: 'Textured Sheet',
	smooth: 'Smooth Sheet',
}

export type KnockOffType = keyof typeof knockOffTypes
export type SheetType = keyof typeof sheets

export interface AutomationParameters {
  id?: string
  file: FileType
  product: ProductType
	zAdjust?: number
	coolBedTo?: number | undefined
  coolBedMidPrint?: boolean
  skipWaitForCoolingBed?: boolean
	meshBedLevelBetweenCycles: boolean
	knockOffType: KnockOffType
	customKnockoffX?: number | undefined
	customKnockoffZ?: number | undefined
	liftPartDistance?: number | undefined
	retract?: number
	additionalDeretract?: number
	overwriteExtruderTemp?: number
	overwriteBedTemp?: number
	speedMultiplier?: number
	frontCenterPart?: boolean
}

interface AutomationSettingsProps {
	file: FileType
	product: ProductType
	children?: React.ReactNode
}

const AutomationSettings: React.FC<AutomationSettingsProps> = ({ file, product, children }) => {
	const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
	const [options, setOptions] = useState<AutomationParameters>({
    product: product,
    file: file,
		meshBedLevelBetweenCycles: true,
		knockOffType: 'default' as KnockOffType,
		frontCenterPart: false,
	})

  const automationSettingsQuery = useQuery(api.products.queries.GET_FILE_AUTOMATION, {
    variables: {
      fileId: file.fileId,
      productId: product.productId
    }
  })
  const [createAutomationSettingsMutation, {loading: creatingAutomationSettings}] = useMutation(api.products.mutations.CREATE_FILE_AUTOMATION)
  const [updateAutomationSettingsMutation, {loading: updatingAutomationSettings}] = useMutation(api.products.mutations.UPDATE_FILE_AUTOMATION)
  const [deleteAutomationSettingsMutation] = useMutation(api.products.mutations.DELETE_FILE_AUTOMATION)

  const createAutomationSettings = () => {
    setLoading(true)
    const { product, file, ...restOptions } = options
    createAutomationSettingsMutation({
      variables: {
        productId: product.productId,
        fileId: file.fileId,
        ...restOptions,
      },
    })
      .then(result => {
        
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
        automationSettingsQuery.refetch()
        setOpen(false)
      })
  }

  const updateAutomationSettings = () => {
    setLoading(true)
    const { product, file, ...filteredOptions } = options
    console.log('Filtered Options ', filteredOptions)
    updateAutomationSettingsMutation({variables: {
      ...filteredOptions
    }})
      .then(result => {
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
        automationSettingsQuery.refetch()
        setOpen(false)
      })
  }

  useEffect(() => {
    if (automationSettingsQuery.data?.fileAutomation) {
      console.log(automationSettingsQuery.data.fileAutomation)
      setOptions(automationSettingsQuery.data.fileAutomation)
    }
  }, [automationSettingsQuery])

  useEffect(() => {
    if (!automationSettingsQuery.loading) {
      setLoading(false)
    }
  }, [automationSettingsQuery.loading])

  useEffect(() => {
    if (open) {
      setLoading(true)
      automationSettingsQuery.refetch()
        .then(result => {
          setLoading(false)
        })
    }
  }, [open])

	return (
		<Dialog
			open={open}
			onOpenChange={(value: boolean) => {
				setOpen(value)
			}}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent className='max-w-64'>
				<DialogHeader>
					<DialogTitle className='flex items-center gap-2'>
            Automation Settings {loading && <Loader className='h-4 w-4 animate-spin' />}
          </DialogTitle>
				</DialogHeader>
				<div className={`automation flex flex-col gap-4 transform-gpu scale-1 rounded-sm bg-bw-background-grey-lighter text-sm transition-all overflow-hidden ease-out-expo duration-300
          ${loading && 'opacity-30 pointer-events-none'}`}>
					<div className='relative w-full flex flex-col gap-1'>
						<Label htmlFor='z-offset' className='flex items-center justify-between gap-1 pr-2'>
							<div>
								Z Adjust <span className='opacity-50'>(Nozzle distance from bed)</span>
							</div>
							<div className='flex items-center gap-1 text-ui-denial-red'>
								<AlertCircle className='h-3 w-3' strokeWidth={3} />
								Careful
							</div>
						</Label>
						<Input
							id='z-offset'
							type='number'
							min={0.0}
							max={1}
							step={0.01}
							placeholder='Cannot be below 0.0mm'
							value={options.zAdjust !== undefined ? options.zAdjust : ''}
							onChange={(e) => {
								const { value, min, max } = e.target
								setOptions((prev) => ({
									...prev,
									zAdjust: value === '' ? 0.0 : Math.max(Math.min(Number(value), Number(max)), Number(min)),
								}))
							}}
						/>
					</div>
					<div className='relative w-full flex flex-col gap-1'>
						<Label htmlFor='print-temperature'>Overwrite extruder temperature</Label>
						<Input
							id='print-temperature'
							type='number'
							min={1}
							max={300}
							placeholder='GCode extruder temp in C'
							value={options.overwriteExtruderTemp}
							onChange={(e) => {
								const { value, min, max } = e.target
								setOptions((prev) => ({
									...prev,
									overwriteExtruderTemp: value === '' ? undefined : Math.max(Math.min(Number(value), Number(max)), Number(min)),
								}))
							}}
						/>
					</div>
					<div className='relative w-full flex flex-col gap-1'>
						<Label htmlFor='extruder-speed' className='flex items-center justify-between gap-1 pr-2'>
							Overwrite print speed w/ multiplier
							<div className='flex items-center gap-1 text-ui-denial-red'>
								<AlertCircle className='h-3 w-3' strokeWidth={3} />
								Careful
							</div>
						</Label>
						<Input
							id='extruder-speed'
							type='number'
							min={0.1}
							max={2.5}
							placeholder='Multiplier 0.1 - 2.5'
							value={options.speedMultiplier}
							onChange={(e) => {
								const { value, min, max } = e.target
								setOptions((prev) => ({
									...prev,
									speedMultiplier: value === '' ? undefined : Math.max(Math.min(Number(value), Number(max)), Number(min)),
								}))
							}}
						/>
					</div>
					<div className='relative w-full flex flex-col gap-1'>
						<Label htmlFor='knockoff-type'>Knock-off type</Label>
						<Select
							value={options.knockOffType}
							onValueChange={(value: string) => {
								setOptions((prev) => ({
									...prev,
									knockOffType: value as KnockOffType,
								}))
							}}>
							<SelectTrigger>
								<SelectValue placeholder='Knock-Off Type' />
							</SelectTrigger>
							<SelectContent>
								{Object.entries(knockOffTypes).map(([key, value]) => {
									return (
										<SelectItem value={key} key={key}>
											{value}
										</SelectItem>
									)
								})}
							</SelectContent>
						</Select>
					</div>
					<div className={`relative w-full max-w-sm flex flex-col gap-1 ${options.knockOffType === 'lift_and_knock' ? '' : 'hidden'}`}>
						<Label htmlFor='custom-lift-inside'>
							Lift move inside part <span className='opacity-50'>(Default: 2.5mm)</span>
						</Label>
						<Input
							id='custom-lift-inside'
							type='number'
							min={0}
							max={5}
							step={0.1}
							placeholder='Y in mm'
							value={options.liftPartDistance !== undefined ? options.liftPartDistance : ''}
							onChange={(e) => {
								const { value, min, max } = e.target
								setOptions((prev) => ({
									...prev,
									liftPartDistance: value === '' ? undefined : Math.max(Math.min(Number(value), Number(max)), Number(min)),
								}))
							}}
						/>
					</div>
					<div className='relative flex items-center gap-1'>
						<div className='relative w-full max-w-sm flex flex-col gap-1'>
							<Label htmlFor='custom-retract'>
								Retract <span className='opacity-50'>(Default: 2mm)</span>
							</Label>
							<Input
								id='custom-retract'
								type='number'
								min={0}
								max={8}
								step={0.1}
								placeholder='Length of retracting + deretracting filament before print starts'
								title='Length of retracting + deretracting filament before print starts'
								value={options.retract !== undefined ? options.retract : ''}
								onChange={(e) => {
									const { value, min, max } = e.target
									setOptions((prev) => ({
										...prev,
										retract: value === '' ? undefined : Math.max(Math.min(Number(value), Number(max)), Number(min)),
									}))
								}}
							/>
						</div>
						<div className='relative w-full max-w-sm flex flex-col gap-1'>
							<Label htmlFor='de-retract'>
								Additional deretract
								<span className='opacity-50'></span>
							</Label>
							<Input
								id='de-retract'
								type='number'
								min={-8}
								max={8}
								step={0.1}
								placeholder='Deretract more than you retracted before a print starts'
								title='Deretract more than you retracted before a print starts'
								value={options.additionalDeretract !== undefined ? options.additionalDeretract : ''}
								onChange={(e) => {
									const { value, min, max } = e.target
									setOptions((prev) => ({
										...prev,
										additionalDeretract: value === '' ? undefined : Math.max(Math.min(Number(value), Number(max)), Number(min)),
									}))
								}}
							/>
						</div>
					</div>
					<div className='relative w-full flex flex-col gap-1'>
						<Label htmlFor='cool-bed-to'>Cool bed inbetween cycles to (Recco: 35)</Label>
						<Input
							id='cool-bed-to'
							type='number'
							min={0}
							max={60}
							step={1}
							placeholder='Bed temperature in Ceclius'
							value={options.coolBedTo !== undefined ? options.coolBedTo : ''}
							onChange={(e) => {
								const { value, min, max } = e.target
								setOptions((prev) => ({
									...prev,
									coolBedTo: value === '' ? undefined : Math.max(Math.min(Number(value), Number(max)), Number(min)),
								}))
							}}
						/>
					</div>
					<div className='flex flex-col gap-0'>
						{options.coolBedTo ? (
							<div className='flex flex-col gap-0'>
								<div className={`flex items-center space-x-1 py-2 px-3 ${options.coolBedTo === undefined ? 'pointer-events-none opacity-30' : ''}`}>
									<Checkbox
										id='cool-bed-mid-print'
										checked={options.coolBedMidPrint}
										onCheckedChange={(checked) => {
											setOptions((prev) => ({ ...prev, coolBedMidPrint: checked as boolean }))
										}}
									/>
									<label htmlFor='cool-bed-mid-print' className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
										Start cooling bed mid print
									</label>
								</div>
								<div className={`flex items-center space-x-1 py-2 px-3`}>
									<Checkbox
										id='skip-wait-for-bed-temp'
										checked={options.skipWaitForCoolingBed}
										onCheckedChange={(checked) => {
											setOptions((prev) => ({ ...prev, skipWaitForCoolingBed: checked as boolean }))
										}}
									/>
									<label
										htmlFor='skip-wait-for-bed-temp'
										className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
										Don't wait for bed to cool
									</label>
								</div>
							</div>
						) : (
							''
						)}

						<div className='flex items-center space-x-1 py-2 px-3'>
							<Checkbox
								id='mesh-bed-level'
								checked={options.meshBedLevelBetweenCycles}
								onCheckedChange={(checked) => {
									setOptions((prev) => ({ ...prev, meshBedLevelBetweenCycles: checked as boolean }))
								}}
							/>
							<label htmlFor='mesh-bed-level' className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
								Mesh-bed-leveling between cycles
							</label>
						</div>

            <div className="flex items-center space-x-1 py-2 px-3">
              <Checkbox id="center-part" checked={options.frontCenterPart} onCheckedChange={(checked) => {
                setOptions(prev => ({...prev, frontCenterPart: checked as boolean}))
              }} />
              <label
                htmlFor="center-part"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Reposition part to centre-front (250x210 bed)
              </label>
            </div>
					</div>
				</div>
        <DialogFooter>
          <div className='flex gap-1 items-center'>
            <Button className='flex gap-1 items-center' variant='bwsecondary' onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button variant='bwconfirm' className='flex gap-1' type='submit' disabled={(creatingAutomationSettings || updatingAutomationSettings)}
              onClick={() => options.id ? updateAutomationSettings() : createAutomationSettings()}>
              {(creatingAutomationSettings || updatingAutomationSettings) ? <Loader className='h-4 w-4 animate-spin' /> : null}
              {options.id 
                ? 'Update automation settings'
                : 'Create automation settings'
              }
            </Button>
          </div>
        </DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default AutomationSettings
