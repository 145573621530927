import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import { Button } from "@/global-components/components/ui/button"
import { Input } from '@/global-components/components/ui/input'
import { Textarea } from '@/global-components/components/ui/textarea'
import { Checkbox } from '@/global-components/components/ui/checkbox'
import { Switch } from "@/global-components/components/ui/switch"
import { useToast } from "@/global-components/components/ui/use-toast"
import CustomFileUpload from '../../components/customFileUpload/CustomFileUpload';
import { Spin } from "react-cssfx-loading";
import helpers from "@/global-components/components/helpers"
import Filament, { FilamentProps } from '@/global-components/components/bw/filament/Filament'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/global-components/components/ui/dialog"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/global-components/components/ui/select"
import { Check, PlusCircle, Info, Film, Loader } from "lucide-react";

import { empty, useMutation, useQuery } from '@apollo/client';
import api from '@/global-components/api'

import './SubmitNewProduct.scss';
import { ProductPreferences, FilamentType } from '@/global-components/types';
import useUserStore from '@/context/useUserStore';

interface ProductForm {
  productTitle: string;
  productDescription: string;
}

type SubmitNewProductProps = {
  fromCard?: boolean;
  isStaff?: boolean;
}


const SubmitNewProduct= (props: SubmitNewProductProps) => {
  const [open, setOpen] = useState(false)
  const [uploading, setUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [productData, setProductData] = useState<ProductForm>({
    productTitle: '',
    productDescription: ''
  });
  const [productPreferencesActive, setProductPreferencesActive] = useState<boolean>(false);
  const [productPreferences, setProductPreferences] = useState<ProductPreferences>({
    indepthPreview: false,
    infill: undefined,
    layerHeight: undefined,
    layerWidth: undefined,
    vaseMode: false,
    vaseModeBase: false,
    nozzleSize: undefined,
  });

  const [submitPossible, setSubmitPossible] = useState<boolean>(false);
  const { toast } = useToast()
  const { user } = useUserStore()
  const navigate = useNavigate();

  const [activeFilamentId, setActiveFilamentId] = useState<string | undefined>(undefined);
  const filamentsQuery = useQuery(api.printers.queries.GET_AVAILABLE_FILAMENTS, {
    skip: !open
  })

  useEffect(() => {
    setSubmitPossible(isProductDataReady());
  }, [productData, selectedFiles, productPreferences, productPreferencesActive, activeFilamentId, productPreferencesActive])

  const productPreferencesReady = (): boolean => {
    if (productPreferencesActive) {
      return !Object.values(productPreferences).includes(undefined) && activeFilamentId !== undefined
    } else {
      return true
    }
  }
  const isProductDataReady = (): boolean => {
    return productData.productTitle !== "" 
      && productData.productDescription !== "" 
      // && (!user?.isStaff ? selectedFiles.filter(helpers.filters.isFileStl).length > 0 : true)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setProductData({
      ...productData,
      [event.target.name]: event.target.value,
    });
  };

  const handlePreferenceChange = (name: string, value: string | number | boolean | undefined) => {
    setProductPreferences((prevPreferences) => ({
      ...prevPreferences, // This spreads the previous state only if it's not null.
      [name]: value ? value : undefined, // This sets the new value for the specific field.
    }));
  };

  const handleFileChange = (files: File[]) => {
    setSelectedFiles(files);
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (submitPossible) {
      setUploading(true);
      if (selectedFiles.length < 1) {
        createProduct({ variables: { productTitle: productData.productTitle, description: productData.productDescription} })
      } else {
        uploadFilesAndProduct({ variables: { files: selectedFiles, productTitle: productData.productTitle, description: productData.productDescription} })
      }
    } 
  };

  const [createProductPreferences] = useMutation(api.products.mutations.CREATE_PART_PREFERENCES, {
    onCompleted: (result: any) => {
      if (result.createPartPreferences.success) {
        helpers.log('preferences created successfully')
      } else if (result.createPartPreferences.errors) {
        toast({
          title: 'Preference creation',
          description: 'Unfortunately, we couldnt transmit the product preferences for you. Please let us know about them in the messages.',
          variant: 'destructive',
          duration: 8000,
        })
      }
    },
    onError: (err: any) => {
      
      toast({
        title: 'Preference creation',
        description: 'Unfortunately, we couldnt transmit the product preferences for you. Please let us know about them in the messages.',
        variant: 'destructive',
        duration: 8000,
      })
    }
  })

  const [createProduct] = useMutation(api.products.mutations.CREATE_PRODUCT, {
    onCompleted: (result: any) => {
      setUploading(false);
      if (result.createProduct.success) {
        toast({
          title: productData.productTitle + ' added successfully',
          variant: 'success',
          duration: 3000,
        })
        if (productPreferencesActive) {
          helpers.log('active filament id ', activeFilamentId);
          createProductPreferences({variables: {
            productId: result.createProduct.createdProduct.productId,
            indepthPreview: productPreferences.indepthPreview,
            infill: productPreferences.infill,
            layerHeight: productPreferences.layerHeight,
            layerWidth: productPreferences.layerWidth,
            vaseMode: productPreferences.vaseMode,
            vaseModeBase: productPreferences.vaseModeBase,
            nozzleSize: productPreferences.nozzleSize,
            filament: activeFilamentId
          }})
        }
        setOpen(false);
        navigate('/product/' + result.createProduct.createdProduct.productVersion[0].reference);
      } else {
        toast({
          title: 'Something went wrong',
          description: result.createProduct.errors  
        })
      }
    },
    onError: (err: any) => {
      setUploading(false);
      toast({
        variant: "destructive",
        title: 'Sorry, something is not working right now',
        description: err.message
      })
    }
  });


  const [uploadFilesAndProduct, { loading, error }] = useMutation(api.products.mutations.UPLOAD_FILE_AND_PRODUCT, {
    onCompleted: (result: any) => {
      setUploading(false);
      if (result.uploadFilesAndProduct.success) {
        toast({
          title: productData.productTitle + ' added successfully',
          variant: 'success',
          description: 'With ' + selectedFiles.length + ' files added successfully',
          duration: 3000
        })
        if (productPreferencesActive) {
          helpers.log('active filament id ', activeFilamentId);
          createProductPreferences({variables: {
            productId: result.uploadFilesAndProduct.uploadedProduct.productId,
            indepthPreview: productPreferences.indepthPreview,
            infill: productPreferences.infill,
            layerHeight: productPreferences.layerHeight,
            layerWidth: productPreferences.layerWidth,
            vaseMode: productPreferences.vaseMode,
            vaseModeBase: productPreferences.vaseModeBase,
            nozzleSize: productPreferences.nozzleSize,
            filament: activeFilamentId
          }})
        }
        setOpen(false);
        navigate('/product/' + result.uploadFilesAndProduct.uploadedProduct.productVersion[0].reference);
      } else {
        setUploading(false)
        toast({
          title: 'Something went wrong',
          description: result.uploadFilesAndProduct.errors
        })
      }
    },
    onError: (err: any) => {
      setUploading(false)
      toast({
        variant: "destructive",
        title: 'Sorry, something is not working right now',
        duration: 5000,
        description: err.message
      })
    }
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {props.fromCard ?
          <div className='submit-product-card flex flex-col gap-2 text-bw-green hover:text-bw-dark-tinted-green justify-center items-center'>
            <PlusCircle className='icon w-6 h-6'/>
            <div className='text-xs text-bw-green font-medium text'>Upload new Product</div>
          </div>
          : <Button variant="bwonbwdarkgreen" size='sm' className='gap-1'>
              <PlusCircle className='icon w-4 h-4'/>
              Add New Product
            </Button>
        }
      </DialogTrigger>
      <DialogContent className='max-w-2xl transform-gpu scale-1'>
        <DialogHeader>
          <DialogTitle className='flex justify-between grow pr-8 relative items-center'>
            Upload New Product
            <div className='flex gap-1 items-center text-xs font-bold max-h-full'>
              <div className={productPreferencesActive ? 'opacity-100' : 'opacity-30'}>PREFERENCES</div>
              <Switch checked={productPreferencesActive ? true : false} onCheckedChange={(checked) => setProductPreferencesActive(checked)} tabIndex={10}/>
            </div>  
          </DialogTitle> 
          
        </DialogHeader>
        <form onSubmit={onSubmit} tabIndex={1}>
          <div className={productPreferencesActive ? "mb-4 transition-all ease-out-expo duration-500" : 'mb-0 transition-all ease-out-expo duration-500'}>
            <div className="flex gap-4 w-full">
              <div className="flex flex-col gap-4 w-full">
                <Input type='text' name='productTitle' tabIndex={14} placeholder="Product title" value={productData.productTitle} onChange={handleInputChange} />
                <Textarea rows={4} className='min-h-[60px]' tabIndex={15} name='productDescription' placeholder="Product description" value={productData.productDescription} onChange={handleInputChange} />
                <CustomFileUpload onFilesChange={handleFileChange} small type='productsubmission' />
                
                <div className={`${productPreferencesActive ? 'py-4 px-4 border border-bw-grey opacity-100' : 'h-0 max-h-0 py-0 px-0 border-none opacity-0'} flex flex-col gap-2 transform-gpu scale-1 rounded-sm bg-bw-background-grey-lighter text-sm transition-all overflow-hidden ease-out-expo duration-300`}>
                  <div className='flex gap-12'>
                    <div className='flex gap-2 items-center transition-all whitespace-nowrap flex-1 justify-between'>
                      Layer height (mm)
                      <Input value={productPreferences.layerHeight ? productPreferences.layerHeight : ''} name='layerHeight' tabIndex={16} onChange={(event) => handlePreferenceChange(event.target.name, event.target.value)} className='flex-0 max-w-24 w-24 text-right'/>
                    </div>
                    <div className='flex gap-2 items-center justify-between transition-all flex-1'>
                      <div className='flex gap-2 items-center transition-all flex-1 justify-between'>
                        Filament
                        <div className='flex-0 max-w-48 w-48'>
                          <Select value={activeFilamentId ? activeFilamentId : ''} onValueChange={(value) => {
                            setActiveFilamentId(value === 'reset' ? undefined : value)
                          }}>
                            <SelectTrigger className="w-full" tabIndex={19}>
                              <SelectValue placeholder="Please select a Material" />
                            </SelectTrigger>
                            <SelectContent className=' max-h-64'>
                              {filamentsQuery.data?.filament.map((filament: FilamentType, index: number) => (
                                <SelectItem key={index} value={filament.filamentId} className='flex' onClick={() => {
                                  if (activeFilamentId === filament.filamentId) {
                                    setActiveFilamentId(undefined)
                                  }
                                }}>
                                  <div className='flex items-center gap-2'><Filament color={filament.colour?.hexCode || '#333333'} active={true} small={true} /> {filament.material.displayName} - {filament.colour.displayName}</div>
                                </SelectItem>
                              ))}
                              <SelectItem key={'reset'} value={'reset'}>
                                <div className='flex items-center gap-2'>None</div>
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex gap-12'>
                    <div className='flex gap-2 items-center transition-all whitespace-nowrap flex-1 justify-between'>
                      <div>Layer width (mm)</div>
                      <Input value={productPreferences.layerWidth ? productPreferences.layerWidth : ''} tabIndex={17} name='layerWidth' onChange={(event) => handlePreferenceChange(event.target.name, event.target.value)} className='flex-0 max-w-24 w-24 text-right'/>
                    </div>
                    <div className='flex gap-2 items-center transition-all flex-1 justify-between'>
                      <div>
                        <div>Vase Mode</div>
                        <div className='text-xs text-bw-green/30'>Single line print</div>
                      </div>
                      <Switch checked={productPreferences.vaseMode} tabIndex={20} name='layerHeight' onCheckedChange={(checked) => handlePreferenceChange('vaseMode', checked)}/>
                    </div>
                  </div>

                  <div className='flex gap-12'>
                    <div className='flex gap-2 items-center transition-all whitespace-nowrap flex-1 justify-between'>
                      Nozzle size (mm)
                      <Input value={productPreferences.nozzleSize ? productPreferences.nozzleSize : ''} name='nozzleSize' tabIndex={18} onChange={(event) => handlePreferenceChange(event.target.name, event.target.value)} className='flex-0 max-w-24 w-24 text-right'/>
                    </div>
                    <div className='flex gap-2 items-center transition-all flex-1 justify-between'>
                      <div>
                        <div>Vase Mode Base</div>
                        <div className="text-xs text-bw-green/30">Print a base</div>
                      </div>
                      <Switch disabled={!productPreferences.vaseMode} checked={productPreferences.vaseModeBase} tabIndex={20} name='vaseModeBase' onCheckedChange={(checked) => handlePreferenceChange('vaseModeBase', checked)}/>
                    </div>
                  </div>

                  <div className='flex gap-12'>
                    <div className='flex gap-2 items-center transition-all whitespace-nowrap flex-1 justify-between'>
                      <div>Infill (%)</div>
                      <Input value={productPreferences.infill ? productPreferences.infill : ''} tabIndex={17} name='infill' onChange={(event) => handlePreferenceChange(event.target.name, event.target.value)} className='flex-0 max-w-24 w-24 text-right'/>
                    </div>
                    <div className='flex gap-2 items-center transition-all flex-1 justify-between opacity-30'>
                      <div>
                        <div>In depth review</div>
                        <div className='text-xs text-bw-green/30'>Extensive feedback</div>
                      </div>
                      <Switch checked={productPreferences.indepthPreview} tabIndex={21} disabled name='layerHeight' onCheckedChange={(checked) => handlePreferenceChange('indepthPreview', checked)}/>
                    </div>
                  </div>

                </div>                
              </div>
            </div>  
          </div>
          <DialogFooter>
            <Button disabled={uploading || !submitPossible} tabIndex={21} variant="bwprimary" type="submit">{uploading ? <Loader className='h-4 w-4 animate-spin' /> : 'Submit'}</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  ) 
}

export default SubmitNewProduct;