import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import api from '@/global-components/api'

import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ProductType } from './global-components/types'

console.log('Current environment: ', process.env.REACT_APP_BRANCH)

const httpLink = createUploadLink({
	uri: api.GRAPHQL_URI + 'api/graphql',
})

let cachedToken: string | null = null

const authLink = setContext((_, { headers }) => {
	if (!cachedToken) {
		cachedToken = api.user.local.getToken()
	}
	return {
		headers: {
			...headers,
			authorization: cachedToken ? `Bearer ${cachedToken}` : '',
		},
	}
})

const timingLink = new ApolloLink((operation, forward) => {
	const startTime = new Date().getTime()
	return forward(operation).map((response) => {
		const duration = new Date().getTime() - startTime
		console.log(`Operation ${operation.operationName} took ${duration}ms`)
		return response
	})
})

const batchLink = new BatchHttpLink({
  uri: api.GRAPHQL_URI + 'api/graphql',
  batchMax: 10,
  batchInterval: 20,
})

const client = new ApolloClient({
	link: ApolloLink.from([authLink, timingLink, httpLink, batchLink]),
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					productsForUser: {
						keyArgs: false, // This ensures the field is always considered the same
						merge(existing = [], incoming) {
							// Create a map of incoming product IDs
							const incomingProductIds = new Map()
							incoming.forEach((product: ProductType) => {
								incomingProductIds.set(product.productId, product)
							})

							// Filter out products that are not in the incoming list
							const merged = existing.filter((product: ProductType) => incomingProductIds.has(product.productId))

							// Add new incoming products that are not in the existing list
							incoming.forEach((product: ProductType) => {
								if (!merged.some((existingProduct: ProductType) => existingProduct.productId === product.productId)) {
									merged.push(product)
								}
							})

							return merged
						},
					},
				},
			},
		},
	}),
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<StrictMode>
		<BrowserRouter>
			<ApolloProvider client={client}>
				<App />
			</ApolloProvider>
		</BrowserRouter>
	</StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
